/* Color Palette*/

:root {
	--dark-blue: #021f3d;
	--light-blue: #2183ca;
	--grey: #a5a5a5;
	--light-grey: #ebebeb;
	--accent: #740955;
}

/* Animations*/

* {
	box-sizing: border-box;
}

body {
	font-family: myriad-pro, sans-serif;
	text-align: center;
	color: var(--dark-blue);
	width: 100%;
	height: 100%;
	margin: 0px auto;
	padding: 0px;
	max-width: 2000px;
	background-color: #f7f7f7;
}

h1 {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 4.5rem;
	color: var(--dark-blue);
	padding: 1rem 0;
	margin: 0;
}

h2 {
	font-size: 2rem;
	font-weight: normal;
	padding: 1rem 0;
}

h3,
p {
	font-size: 1.25rem;
	line-height: 1.5;
}

button {
	cursor: pointer;
}

a,
a:visited {
	color: var(--dark-blue);
}

::placeholder {
	font-family: myriad-pro, sans-serif;
	padding: 0.5rem;
	font-size: 1rem;
}

/* Hero Section */

.contact {
	font-weight: normal;
}

.hero {
	background-image: url(/images/physio.jpg);
	background-size: cover;
	background-position: top;
}

.hero-container {
	min-height: 75vh;
	padding: 6rem;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.underline {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, #2183ca 70%);
	background-size: 100% 75%;
}

.title {
	text-align: left;
	line-height: 1.5;
	max-width: 50%;
}

.title-thank-you {
	max-width: 75%;
	padding: 15rem 5rem 15rem;
	text-align: left;
}

/* Checkerboard */

.checker-board-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-auto-rows: 1fr;
}

.checker {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem 7.5rem;
	transition: all 0.2s ease-in-out;
	background-color: #fff;
}

.checker:hover,
.active {
	background-color: var(--light-blue) !important;
	color: #fff;
	transform: scale(1, 1.05);
	border-radius: 10px;
}

.checker-board-container div:nth-child(even) {
	background-color: var(--light-grey);
}

.checker-board-container div:nth-child(even):hover {
	background-color: var(--light-blue);
	color: #fff;
	transform: scale(1, 1.05);
	border-radius: 10px;
}

/* Sponsors */

.sponsors-container {
	background-color: #fff;
	padding: 3rem;
}

.sponsors-logos {
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.sponsors-container img {
	max-width: 15%;
}

/* Address Form */

.form-container {
	color: var(--light-grey);
	background-color: var(--dark-blue);
	padding: 5rem 1rem;
	display: flex;
	flex-direction: column;
}

form {
	padding: 2rem;
	max-width: 50%;
	margin: auto;
}

input {
	font-family: inherit;
	font-size: inherit;
	outline: none;
	border: none;
	border-radius: 10px;
	box-shadow: 2px 2px 2px var(--grey);
	padding: 10px;
	margin: 5px 0;
	width: 100%;
}

input:focus {
	border: solid;
	border-color: var(--grey);
	border-radius: 10px;
	box-shadow: 2px 2px 2px var(--grey);
	padding: 10px;
	margin: 5px 0;
	width: 100%;
}

button {
	appearance: none;
	-webkit-appearance: none;
	margin: 20px 0;
	padding: 20px;
	border: none;
	background-color: var(--light-blue);
	color: #fff;
	font-weight: 600;
	font-size: 1.2rem;
	border-radius: 10px;
	width: 100%;
	transition: all 0.2s ease-in-out;
}

#sign-up-form button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

#sign-up-form button:disabled:hover {
	transform: none;
}

#signup-button {
	transform: scale(1.02);
}

.frc-captcha {
	margin: 15px auto 0px;
}

/* Footer  */

.footer a,
.footer a:visited {
	color: var(--dark-blue);
	padding: 2rem;
}

.footer {
	display: flex;
	justify-content: center;
	margin: auto;
	background-color: #fff;
	color: var(--dark-blue);
}

/* Cookie Banner */

#consent-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 10px rgb(0 0 0 / 30%);
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}

#consent-popup p {
	font-size: 1em;
	line-height: normal;
}

#consent-popup button {
	margin: 1em;
	padding: 0.5em 0.1em;
	font-size: 1em;
	appearance: none;
	-webkit-appearance: none;
	max-width: 100px;
	border-radius: 10px;
	transition: all 0.2s ease-in-out;
}

#accept {
	color: #fff;
	font-weight: 600;
	background-color: var(--light-blue);
	border: none;
}

.hidden {
	visibility: hidden;
}

.title-thank-you a {
	color: var(--light-blue);
}

/* Adjustments for Ultra-Wide Screens */

@media (min-width: 2000px) {
	.hero {
		background-position: 0 -120px;
	}
	.hero-container {
		padding: 12rem;
	}
	.sponsors-container img {
		max-height: 15%;
		max-width: 15%;
	}
}

/* Adjustments for Tablets */

@media (max-width: 1200px) {
	h1 {
		font-size: 3.5rem;
	}

	h2 {
		font-size: 1.75rem;
	}
	.title {
		max-width: 75%;
	}

	.hero {
		background-position: 65%;
	}

	.hero-container {
		padding: 3rem;
	}

	form {
		padding: 2rem 0 0;
	}

	.checker-board-container {
		grid-template-columns: repeat(2, 1fr);
		font-size: 1rem;
		background-color: #fff;
	}
	.checker-board-container div:nth-child(1) {
		background-color: var(--light-grey);
	}
	.checker-board-container div:nth-child(2) {
		background-color: #fff;
	}
	.checker-board-container div:nth-child(4) {
		background-color: var(--light-grey);
	}
	.checker-board-container div:nth-child(5) {
		background-color: var(--light-grey);
	}
	.checker-board-container div:nth-child(6) {
		background-color: #fff;
	}

	.checker {
		padding: 3rem;
	}

	.sponsors-container img {
		min-height: 35%;
		min-width: 35%;
		padding: 2rem;
	}

	#consent-popup {
		padding: 1rem;
		display: flex;
		flex-direction: column;
		left: 0;
		right: 0;
	}

	#consent-popup p {
		max-width: 70%;
	}

	#consent-popup button {
		width: 70%;
		margin: 0.5rem;
	}
}

/* Adjustments for Phones */

@media (max-width: 650px) {
	h1 {
		font-size: 3rem;
	}

	h2 {
		font-size: 1.5rem;
	}
	.hero-container {
		padding: 2rem;
	}

	button {
		font-size: 1rem;
	}

	.checker-board-container {
		grid-template-columns: repeat(1, 1fr);
	}
	.checker-board-container div:nth-child(even) {
		background-color: #fff;
	}
	.checker-board-container div:nth-child(odd) {
		background-color: var(--light-grey);
	}

	.sponsors-logos {
		flex-direction: column;
		padding: 0;
	}

	.sponsors-container {
		padding: 2.5rem;
	}

	.sponsors-container p {
		padding-bottom: 1rem;
	}

	.sponsors-container img {
		min-height: 50%;
		min-width: 50%;
		padding: 3rem 0 3rem 0;
	}
	.form-container {
		padding: 2rem;
	}
	form {
		max-width: 90%;
		margin: auto;
	}

	.footer a {
		font-size: 0.95rem;
	}
}
