:root {
  --dark-blue: #021f3d;
  --light-blue: #2183ca;
  --grey: #a5a5a5;
  --light-grey: #ebebeb;
  --accent: #740955;
}

* {
  box-sizing: border-box;
}

body {
  text-align: center;
  color: var(--dark-blue);
  background-color: #f7f7f7;
  width: 100%;
  max-width: 2000px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: myriad-pro, sans-serif;
}

h1 {
  color: var(--dark-blue);
  margin: 0;
  padding: 1rem 0;
  font-family: Poppins, sans-serif;
  font-size: 4.5rem;
  font-weight: 700;
}

h2 {
  padding: 1rem 0;
  font-size: 2rem;
  font-weight: normal;
}

h3, p {
  font-size: 1.25rem;
  line-height: 1.5;
}

button {
  cursor: pointer;
}

a, a:visited {
  color: var(--dark-blue);
}

::placeholder {
  padding: .5rem;
  font-family: myriad-pro, sans-serif;
  font-size: 1rem;
}

.contact {
  font-weight: normal;
}

.hero {
  background-image: url("physio.5008db03.jpg");
  background-position: top;
  background-size: cover;
}

.hero-container {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 75vh;
  margin: auto;
  padding: 6rem;
  display: flex;
}

.underline {
  background-image: linear-gradient(#0000 80%, #2183ca 70%);
  background-size: 100% 75%;
}

.title {
  text-align: left;
  max-width: 50%;
  line-height: 1.5;
}

.title-thank-you {
  text-align: left;
  max-width: 75%;
  padding: 15rem 5rem;
}

.checker-board-container {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  display: grid;
}

.checker {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 7.5rem;
  transition: all .2s ease-in-out;
  display: flex;
}

.checker:hover, .active {
  color: #fff;
  border-radius: 10px;
  transform: scale(1, 1.05);
  background-color: var(--light-blue) !important;
}

.checker-board-container div:nth-child(2n) {
  background-color: var(--light-grey);
}

.checker-board-container div:nth-child(2n):hover {
  background-color: var(--light-blue);
  color: #fff;
  border-radius: 10px;
  transform: scale(1, 1.05);
}

.sponsors-container {
  background-color: #fff;
  padding: 3rem;
}

.sponsors-logos {
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.sponsors-container img {
  max-width: 15%;
}

.form-container {
  color: var(--light-grey);
  background-color: var(--dark-blue);
  flex-direction: column;
  padding: 5rem 1rem;
  display: flex;
}

form {
  max-width: 50%;
  margin: auto;
  padding: 2rem;
}

input {
  font-family: inherit;
  font-size: inherit;
  box-shadow: 2px 2px 2px var(--grey);
  border: none;
  border-radius: 10px;
  outline: none;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
}

input:focus {
  border: solid;
  border-color: var(--grey);
  box-shadow: 2px 2px 2px var(--grey);
  border-radius: 10px;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
}

button {
  appearance: none;
  background-color: var(--light-blue);
  color: #fff;
  border: none;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all .2s ease-in-out;
}

#sign-up-form button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

#sign-up-form button:disabled:hover {
  transform: none;
}

#signup-button {
  transform: scale(1.02);
}

.frc-captcha {
  margin: 15px auto 0;
}

.footer a, .footer a:visited {
  color: var(--dark-blue);
  padding: 2rem;
}

.footer {
  color: var(--dark-blue);
  background-color: #fff;
  justify-content: center;
  margin: auto;
  display: flex;
}

#consent-popup {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 10px #0000004d;
}

#consent-popup p {
  font-size: 1em;
  line-height: normal;
}

#consent-popup button {
  appearance: none;
  border-radius: 10px;
  max-width: 100px;
  margin: 1em;
  padding: .5em .1em;
  font-size: 1em;
  transition: all .2s ease-in-out;
}

#accept {
  color: #fff;
  background-color: var(--light-blue);
  border: none;
  font-weight: 600;
}

.hidden {
  visibility: hidden;
}

.title-thank-you a {
  color: var(--light-blue);
}

@media (width >= 2000px) {
  .hero {
    background-position: 0 -120px;
  }

  .hero-container {
    padding: 12rem;
  }

  .sponsors-container img {
    max-width: 15%;
    max-height: 15%;
  }
}

@media (width <= 1200px) {
  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  .title {
    max-width: 75%;
  }

  .hero {
    background-position: 65%;
  }

  .hero-container {
    padding: 3rem;
  }

  form {
    padding: 2rem 0 0;
  }

  .checker-board-container {
    background-color: #fff;
    grid-template-columns: repeat(2, 1fr);
    font-size: 1rem;
  }

  .checker-board-container div:first-child {
    background-color: var(--light-grey);
  }

  .checker-board-container div:nth-child(2) {
    background-color: #fff;
  }

  .checker-board-container div:nth-child(4), .checker-board-container div:nth-child(5) {
    background-color: var(--light-grey);
  }

  .checker-board-container div:nth-child(6) {
    background-color: #fff;
  }

  .checker {
    padding: 3rem;
  }

  .sponsors-container img {
    min-width: 35%;
    min-height: 35%;
    padding: 2rem;
  }

  #consent-popup {
    flex-direction: column;
    padding: 1rem;
    display: flex;
    left: 0;
    right: 0;
  }

  #consent-popup p {
    max-width: 70%;
  }

  #consent-popup button {
    width: 70%;
    margin: .5rem;
  }
}

@media (width <= 650px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .hero-container {
    padding: 2rem;
  }

  button {
    font-size: 1rem;
  }

  .checker-board-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .checker-board-container div:nth-child(2n) {
    background-color: #fff;
  }

  .checker-board-container div:nth-child(odd) {
    background-color: var(--light-grey);
  }

  .sponsors-logos {
    flex-direction: column;
    padding: 0;
  }

  .sponsors-container {
    padding: 2.5rem;
  }

  .sponsors-container p {
    padding-bottom: 1rem;
  }

  .sponsors-container img {
    min-width: 50%;
    min-height: 50%;
    padding: 3rem 0;
  }

  .form-container {
    padding: 2rem;
  }

  form {
    max-width: 90%;
    margin: auto;
  }

  .footer a {
    font-size: .95rem;
  }
}
/*# sourceMappingURL=index.6826fcaa.css.map */
